/*Sacop Listagem de informações e arquivos*/
.containerInfor{
    max-width: 1201px;
    margin: 0 auto;
    padding: 50px 0;
    font-size: 0.9rem;
    /* display: grid; */
    /* grid-template-columns: 200px 200px 200px; */
}
.rowInfor{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    padding: 10px 0;
    text-transform: uppercase;

}
.rowInforItem1{
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
    padding: 14px 0;
    text-transform: uppercase;
    text-align: justify;

}
@media screen and (max-width: 1300px) {
    .containerInfor{
        padding: 50px 22px;
       
    }
}
@media screen and (max-width: 740px) {
    .rowInfor{
        grid-template-columns: 1fr;
        grid-row-gap: 25px;
        padding: 10px 0;
        font-size: 0.9em;
    }
    .rowInforItem1{
        font-size: 0.9em;
    }
}
